import { useAppRouter } from 'src/@legacy/@core/hooks/UseAppRouter'
import { ColorsStyle } from 'src/@legacy/@core/style/base/colors.style'
import { FontsStyle } from 'src/@legacy/@core/style/base/fonts.style'
import { IssueStyle } from 'src/@legacy/@core/style/base/issue.style'
import { ResetStyle } from 'src/@legacy/@core/style/base/reset.style'
import { SidebarStyle } from 'src/@legacy/@core/style/layout/sidebar.style'
import { AntdStyle } from 'src/@legacy/@core/style/utilities/antd.style'
import { MixinsStyle } from 'src/@legacy/@core/style/utilities/mixins.style'
import { TextStyle } from 'src/@legacy/@core/style/utilities/text.style'
import { VariableStyle } from 'src/@legacy/@core/style/utilities/variable.style'
import { setOn401, setOn403, useRoutingCollector } from '../@core/utilities/Client'
import Head from 'next/head'
import 'nprogress/nprogress.css'
import React, { FC, useEffect } from 'react'
import CrispChat from 'src/@legacy/utilities/CrispChat'
import env from 'src/@legacy/utilities/env'
import { useCollectHistory } from '../hooks/Common'
import { LINK_URL_LOGIN } from '../utilities/LinkURL'
import GleapWidget from '../utilities/GleapWidget'

const DefaultLayout: FC<{}> = ({ children }) => {
  const router = useAppRouter()
  useEffect(() => {
    setOn401(() => router.push(LINK_URL_LOGIN))
    setOn403(() => router.push(LINK_URL_LOGIN))
  }, [])

  return (
    <>
      <Head>
        <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}></script>
        <link rel="apple-touch-icon" href={`${env.PUBLIC_EMPLOYER_APP_DOMAIN}/img/apple-touch-icon.png`} />
        {process.env.NEXT_PUBLIC_NO_INDEX === 'true' && <meta name="robots" content="noindex" />}
        <title>freeC - Website tuyển dụng thông minh</title>
        <link rel="shortcut icon" type="image/png" sizes="32x32" href="/favicon_32x32.png"></link>
        <link rel="shortcut icon" type="image/png" sizes="16x16" href="/favicon_16x16.png"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap"
          rel="stylesheet"></link>
        {env.PUBLIC_GTM_KEY && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${env.PUBLIC_GTM_KEY}');`
            }}
          />
        )}
        {/* {env.NEXT_PUBLIC_APP_ENVIRONMENT === 'production' && (
          <script defer data-domain="employer.freec.asia" src="https://tracking.freec.asia/js/plausible.js"></script>
        )} */}
      </Head>

      {process.env.PUBLIC_GTM_KEY && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${env.PUBLIC_GTM_KEY}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}></iframe>
        </noscript>
      )}
      {process.env.NEXT_PUBLIC_ENABLE_LIVE_CHAT === 'true' && <CrispChat />}
      {env.NEXT_PUBLIC_ENABLE_GLEAP && (
        <>
          <GleapWidget apiKey={env.NEXT_PUBLIC_ENABLE_GLEAP} />
        </>
      )}
      {children}
    </>
  )
}

export const LayoutProvider: FC<{}> = ({ children }) => {
  const router = useAppRouter()
  useRoutingCollector(router)
  useCollectHistory()

  const style = (
    <>
      <style jsx global>
        {AntdStyle}
      </style>
      <style jsx global>
        {VariableStyle}
      </style>
      <style jsx global>
        {ResetStyle}
      </style>
      <style jsx global>
        {IssueStyle}
      </style>
      <style jsx global>
        {TextStyle}
      </style>
      <style jsx global>
        {FontsStyle}
      </style>
      <style jsx global>
        {ColorsStyle}
      </style>
      <style jsx global>
        {MixinsStyle}
      </style>
      <style jsx global>
        {SidebarStyle}
      </style>
    </>
  )

  return (
    <>
      {style}
      <DefaultLayout>{children}</DefaultLayout>
    </>
  )
}

export default DefaultLayout
