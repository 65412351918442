import { useData } from '../store'
import { Action, ClientResponse, JobType, LocationType, FastJsonList } from '../../type/Common'
import {
  API_URL_SKILLS,
  API_URL_JOB_CATEGORIES,
  API_URL_CREATE_JOB,
  API_URL_LIST_JOB,
  API_URL_GET_JOB_DETAIL,
  API_URL_COMPANY_APPLICANTS_STAGE,
  API_URL_JOBS_CANDIDATES,
  API_URL_JOB_NOTES,
  API_URL_UPDATE_JOB_NOTES
} from '../../utilities/APIConstant'
import { AxiosResponse } from 'axios'
import { ENUM_JOB_STATUS } from '../../utilities/Enum'
import { toPagingAPI } from '../../utilities/common'
import client from '../../@core/utilities/Client'
import { useClient } from 'src/@legacy/@core/hooks/useClient'

const JOB_DATA_CHANGE = 'JOB_DATA_CHANGE'
interface JobStatus {
  id: number
  name: string
}
export interface JobLangModal {
  id: number
  name: string
  name_vn: string
}
interface JobLevelItem {
  id: number
  name: string
  name_vn: string
}
interface EmploymentType {
  id: number
  name: string
  name_vn: string
}

interface YOEType {
  id: number
  name: string
  name_vn: string
}

export interface Skill<extra = {}> {
  id: number
  type?: string
  attributes: {
    id: number
    name: string
  } & extra
}

export interface JobCategories {
  id: number
  type: string
  attributes: {
    id: number
    name: string
  }
}

export interface JobScreenType {
  skills?: Array<Skill>
  job_categories?: Array<JobCategories>
  job_data?: FastJsonList<JobType, string>
  job_count_status?: any
  job_orders_data?: FastJsonList<JobType, string>
  job_orders_count?: any
  // todo: refactor it

  readyForNext: boolean
  loadingJob: boolean
  loadingJobOrders: boolean
  firstLoadSuccess: boolean
  firstLoadJobOrdersSuccess: boolean
  locationList?: FastJsonList<LocationType>
}

export const JobReducer = (
  state: JobScreenType = {
    readyForNext: false,
    loadingJob: true,
    firstLoadSuccess: false,
    firstLoadJobOrdersSuccess: false,
    loadingJobOrders: false
  },
  action: Action
): JobScreenType => {
  switch (action.type) {
    case JOB_DATA_CHANGE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

const getSkill = () =>
  client.get(API_URL_SKILLS, {}).then((response) => {
    return response.data
  })

type JobStageCountType = {
  all_published: number
  all_draft: number
  all_reviewing: number
  all_closed: number
  all_rejected: number
}
export const useJobData = (): {
  job: JobScreenType
  fetchListSkills: () => Promise<void>
  fetchListJobCategories: () => Promise<void>
  fetchLocations: () => Promise<void>
  jobLevel: Array<JobLevelItem>
  jobStatus: Array<JobStatus>
  employmentType: Array<EmploymentType>
  yoeList: Array<YOEType>
  jobLangs: Array<JobLangModal>
  jobProficiencies: Array<JobLangModal>
  createNewJob: (data) => Promise<ClientResponse>
  fetchJobDetailById: (jobId) => Promise<ClientResponse>
  fetchListJob: (args: { q: string | string[]; status: string | string[]; freec_status: string }, page: number) => Promise<ClientResponse>
  job_data?: FastJsonList<JobType, string>
  job_count_status?: any
  job_orders_data?: FastJsonList<JobType, string>
  job_orders_count?: any
  noMoreJob: boolean
  loadingJob: boolean
  loadingJobOrders: boolean
  firstLoadSuccess: boolean
  firstLoadJobOrdersSuccess: boolean
  updateJobById: (jobId, data) => Promise<ClientResponse>
  deleteJob: (jobId: number | string) => Promise<ClientResponse>
  closeJob: (jobId: number | string) => Promise<ClientResponse>
  reopenJob: (jobId: number | string) => Promise<ClientResponse>
  updateApplicantsStage: (candidate_id, job_stage_id, new_index) => Promise<ClientResponse>
  updateCountAfterReload: (job_orders_data: any, job_orders_count: any) => void
  fetchJobApplicant: (jobId) => Promise<ClientResponse>
  createJobNotes: (jobId, data) => Promise<ClientResponse>
  updateJobNotes: (jobId, id, data) => Promise<ClientResponse>
  deleteJobNotes: (jobId, id) => Promise<ClientResponse>
  updateCountJobPost: (data: any) => void
} => {
  // todo: Refactoring code below for readability
  // separate concern on paging and job fetching
  const { state, dispatch } = useData()
  const { client } = useClient()
  const job = state?.job
  const job_data = job?.job_data
  const job_count_status = job?.job_count_status
  const job_orders_data = job?.job_orders_data
  const job_orders_count = job?.job_orders_count
  const readyForNext = job?.readyForNext
  const loadingJob = job?.loadingJob
  const loadingJobOrders = job?.loadingJobOrders
  const firstLoadSuccess = job?.firstLoadSuccess
  const firstLoadJobOrdersSuccess = job?.firstLoadJobOrdersSuccess
  const jobLangs = [
    { id: 0, name: 'English', name_vn: 'Tiếng Anh' },
    { id: 37, name: 'Vietnamese', name_vn: 'Tiếng Việt' },
    { id: 1, name: 'Japanese', name_vn: 'Tiếng Nhật' },
    { id: 2, name: 'Korean', name_vn: 'Tiếng Hàn' },
    { id: 4, name: 'Chinese (Cantonese)', name_vn: 'Tiếng Quảng Đông (Trung)' },
    { id: 18, name: 'Chinese (Mandarin)', name_vn: 'Tiếng Phổ Thông (Trung)' },
    { id: 11, name: 'Arabic', name_vn: 'Tiếng Ả rập' },
    { id: 12, name: 'Bengali', name_vn: 'Tiếng Bangladesh' },
    { id: 13, name: 'Bulgarian', name_vn: 'Tiếng Bungari' },
    { id: 14, name: 'Burmese', name_vn: 'Tiếng Miến Điện (Myanmar)' },
    { id: 15, name: 'Cambodian', name_vn: 'Tiếng Campuchia' },
    { id: 16, name: 'Cebuano', name_vn: 'Tiếng Bisaya (Philippines)' },
    { id: 17, name: 'Czech', name_vn: 'Tiếng Séc' },
    { id: 19, name: 'Danish', name_vn: 'Tiếng Đan Mạch' },
    { id: 20, name: 'Dutch', name_vn: 'Tiếng Hà Lan' },
    { id: 21, name: 'Finnish', name_vn: 'Tiếng Phần Lan' },
    { id: 3, name: 'French', name_vn: 'Tiếng Pháp' },
    { id: 10, name: 'German', name_vn: 'Tiếng Đức' },
    { id: 22, name: 'Greek', name_vn: 'Tiếng Hy Lạp' },
    { id: 9, name: 'Hindi', name_vn: 'Tiếng Ấn Độ' },
    { id: 23, name: 'Hungarian', name_vn: 'Tiếng Hungari' },
    { id: 24, name: 'Indonesian', name_vn: 'Tiếng Indonesia' },
    { id: 8, name: 'Italian', name_vn: 'Tiếng Ý' },
    { id: 25, name: 'Javanese', name_vn: 'Tiếng Java (Indonesia)' },
    { id: 26, name: 'Laotian', name_vn: 'Tiếng Lào' },
    { id: 27, name: 'Malay', name_vn: 'Tiếng Mã Lai (Malaysia)' },
    { id: 28, name: 'Norwegian', name_vn: 'Tiếng Na Uy' },
    { id: 29, name: 'Polish', name_vn: 'Tiếng Ba Lan' },
    { id: 30, name: 'Portuguese', name_vn: 'Tiếng Bồ Đào Nha' },
    { id: 31, name: 'Romanian', name_vn: 'Tiếng Romanian' },
    { id: 7, name: 'Russian', name_vn: 'Tiếng Nga' },
    { id: 6, name: 'Spanish', name_vn: 'Tiếng Tây Ban Nha' },
    { id: 32, name: 'Swedish', name_vn: 'Tiếng Thụy Điển' },
    { id: 33, name: 'Tagolog', name_vn: 'Tiếng Tagalog (Philippines)' },
    { id: 34, name: 'Taiwanese', name_vn: 'Tiếng Đài Loan' },
    { id: 5, name: 'Thai', name_vn: 'Tiếng Thái' },
    { id: 35, name: 'Turkish', name_vn: 'Tiếng Thổ Nhĩ Kỳ' },
    { id: 36, name: 'Ukranian', name_vn: 'Tiếng Ukraina' }
  ]
  const jobProficiencies = [
    { name: 'Basic', name_vn: 'Cơ bản', id: 0 },
    { name: 'Intermediate', name_vn: 'Trung cấp', id: 1 },
    { name: 'Advanced', name_vn: 'Cao cấp', id: 2 },
    { name: 'Expert', name_vn: 'Chuyên gia', id: 3 }
  ]
  // const jobLevel = [
  //   { id: 0, name: 'Intern', name_vn: 'Thực tập' },
  //   { id: 1, name: 'Junior', name_vn: 'Mới tốt nghiệp' },
  //   { id: 2, name: 'Senior', name_vn: 'Chuyên viên' },
  //   { id: 3, name: 'Supervisor', name_vn: 'Trưởng nhóm/Giám sát' },
  //   { id: 4, name: 'Manager', name_vn: 'Trưởng phòng' },
  //   { id: 5, name: 'Director & above', name_vn: 'Giám đốc và vị trí cấp cao' }
  // ]
  const jobLevel = [
    { id: 0, name: 'Intern', name_vn: 'Thực tập sinh / sinh viên' },
    { id: 1, name: 'Junior', name_vn: 'Mới tốt nghiệp / nhân viên' },
    { id: 2, name: 'Senior', name_vn: 'Chuyên viên' },
    { id: 3, name: 'Supervisor / leader', name_vn: 'Giám sát / trưởng nhóm' },
    { id: 4, name: 'Manager', name_vn: 'Quản lý' },
    { id: 5, name: 'Director & above', name_vn: 'Giám đốc & vị trí cấp cao' }
  ]
  const employmentType = [
    { id: 0, name: 'Full-time', name_vn: 'Toàn thời gian' },
    { id: 1, name: 'Part-time', name_vn: 'Bán thời gian' },
    { id: 2, name: 'Intern', name_vn: 'Thực tập' },
    { id: 5, name: 'Freelancer', name_vn: 'Freelancer' },
    { id: 9, name: 'Remote work', name_vn: 'Làm việc từ xa' },
    { id: 6, name: 'Hybrid work', name_vn: 'Làm việc hybrid' }
  ]

  const yoeList = [
    { id: 0, name: 'under 1 year', name_vn: 'dưới 1 năm' },
    { id: 1, name: '1 year', name_vn: '1 năm' },
    { id: 2, name: '2 years', name_vn: '2 năm' },
    { id: 3, name: '3 years', name_vn: '3 năm' },
    { id: 4, name: '4 years', name_vn: '4 năm' },
    { id: 5, name: '5 years', name_vn: '5 năm' },
    { id: 6, name: '6 years', name_vn: '6 năm' },
    { id: 7, name: '7 years', name_vn: '7 năm' },
    { id: 8, name: '8 years', name_vn: '8 năm' },
    { id: 9, name: '9 years', name_vn: '9 năm' },
    { id: 10, name: '10 years', name_vn: '10 năm' },
    { id: 11, name: 'over 10 years', name_vn: 'hơn 10 năm' }
  ]

  const jobStatus = [
    { id: 0, name: 'Draft' },
    { id: 1, name: 'Published' },
    { id: 2, name: 'Closed' }
  ]

  const fetchLocations = () =>
    client.get(`/api/v2/public/locations/`, {}).then((rs: AxiosResponse<FastJsonList<LocationType>>) => {
      const locationList = rs.data
      dispatch({ type: JOB_DATA_CHANGE, payload: { locationList } })
    })

  const fetchListSkills = () => {
    return getSkill().then((data) => {
      dispatch({ type: JOB_DATA_CHANGE, payload: { skills: data } })
    })
  }

  const fetchListJobCategories = () =>
    client.get(API_URL_JOB_CATEGORIES, {}).then((response) => {
      const job_categories = response.data.data
      dispatch({ type: JOB_DATA_CHANGE, payload: { job_categories } })
    })

  const createNewJob = (data: any) => {
    const { job_languages_attributes, recruiter_ids } = data
    const params = {
      ...data,
      job_languages_attributes: job_languages_attributes.filter((lang) => lang.language !== undefined),
      recruiter_ids: recruiter_ids
    }
    return client.post(API_URL_CREATE_JOB, { data: params })
  }

  const fetchListJob = ({ q, status, freec_status }, page: number) => {
    dispatch({ type: JOB_DATA_CHANGE, payload: { loadingJob: true } })
    return client
      .get(toPagingAPI(API_URL_LIST_JOB, { title: q, status, freec_status }, page), {})
      .then((res: AxiosResponse<[FastJsonList<JobScreenType, string>, JobStageCountType]>) => {
        const [jobScreenData, job_count_status] = res.data
        dispatch({
          type: JOB_DATA_CHANGE,
          payload: {
            job_data: jobScreenData,
            job_count_status: job_count_status,
            readyForNext: !!jobScreenData?.meta?.next,
            loadingJob: false,
            firstLoadSuccess: true
          }
        })
        return res
      })
  }

  const updateCountJobPost = (data: any) => {
    return dispatch({
      type: JOB_DATA_CHANGE,
      payload: {
        job_count_status: data
      }
    })
  }

  const updateCountAfterReload = (job_orders_data, job_orders_count) => {
    dispatch({
      type: JOB_DATA_CHANGE,
      payload: {
        job_orders_count,
        job_orders_data: { ...job_orders_data }
      }
    })
  }

  const fetchJobDetailById = (jobId) => {
    return client.get(`${API_URL_GET_JOB_DETAIL}/${jobId}`, {})
  }

  const fetchJobApplicant = (jobId) => {
    return client.get(API_URL_JOBS_CANDIDATES(jobId), {})
  }
  const updateJobById = (jobId, data) => {
    const { job_languages_attributes, recruiter_ids } = data
    const params = {
      ...data,
      job_languages_attributes: job_languages_attributes.filter((lang) => lang.language !== undefined),
      recruiter_ids: recruiter_ids
    }
    return client.put(`${API_URL_GET_JOB_DETAIL}/${jobId}`, { data: params })
  }

  const updateApplicantsStage = (candidate_id, job_stage_id, new_index) => {
    return client.put(`${API_URL_COMPANY_APPLICANTS_STAGE(candidate_id)}`, { data: { job_stage_id: job_stage_id, new_index: new_index } })
  }

  const createJobNotes = (jobId, data) => {
    const { content } = data
    const params = {
      ...data,
      content: content
    }
    return client.post(API_URL_JOB_NOTES(jobId), { data: params })
  }
  const updateJobNotes = (jobId, id, data) => {
    const { content } = data
    const params = {
      ...data,
      content: content
    }
    return client.put(API_URL_UPDATE_JOB_NOTES(jobId, id), { data: params })
  }
  const deleteJobNotes = (jobId, id) => {
    return client.delete(API_URL_UPDATE_JOB_NOTES(jobId, id), {})
  }
  const deleteJob = (jobId: number) => client.delete(`${API_URL_GET_JOB_DETAIL}/${jobId}`, {})
  const closeJob = (jobId: number) => client.put(`${API_URL_GET_JOB_DETAIL}/${jobId}`, { data: { status: ENUM_JOB_STATUS.closed } })
  const reopenJob = (jobId: number) => client.put(`${API_URL_GET_JOB_DETAIL}/${jobId}`, { data: { status: ENUM_JOB_STATUS.published } })
  return {
    updateCountAfterReload,
    jobLevel,
    employmentType,
    yoeList,
    job,
    jobLangs,
    jobProficiencies,
    fetchListSkills,
    fetchListJobCategories,
    fetchLocations,
    createNewJob,
    fetchListJob,
    job_data,
    job_count_status,
    job_orders_data,
    job_orders_count,
    noMoreJob: !readyForNext,
    loadingJob,
    loadingJobOrders,
    firstLoadSuccess,
    firstLoadJobOrdersSuccess,
    fetchJobDetailById,
    updateJobById,
    jobStatus,
    deleteJob,
    closeJob,
    reopenJob,
    updateApplicantsStage,
    fetchJobApplicant,
    createJobNotes,
    updateJobNotes,
    deleteJobNotes,
    updateCountJobPost
  }
}
