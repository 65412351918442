import { useCurrentUser } from 'src/@legacy/@core/hooks/CurrentUserHooks'
import { useJWTTokenPayload } from 'src/@legacy/@core/hooks/JWTHooks'
import React, { FC, useCallback, useEffect } from 'react'
import Gleap from 'gleap'
import useAppRouter from '../@optimal-page/hooks/useAppRouter'

export const createSingleTimeInvoke = <F extends Function>(func: F) => {
  let invoked = false
  return function (...args) {
    if (invoked === false) {
      //@ts-ignore
      func(...args)
      invoked = true
    }
  }
}

const innitalize = createSingleTimeInvoke((apiKey) => {
  Gleap.initialize(apiKey)
  window.Gleap = Gleap
})
const GleapWidget: FC<{ apiKey: string }> = ({ apiKey }) => {
  const jwtokenPayload = useJWTTokenPayload()
  const [currentUser] = useCurrentUser()
  const router = useAppRouter()
  const checkAndOpenChat = useCallback(() => {
    if (router.query.openChat) {
      Gleap.open()
    }
  }, [router])
  useEffect(() => {
    if (window.Gleap) {
      checkAndOpenChat()
    }
  }, [checkAndOpenChat, router])

  useEffect(() => {
    innitalize(apiKey)
    if (jwtokenPayload && jwtokenPayload.user_type !== undefined) {
      if (currentUser?.profile) {
        //@ts-ignore
        if (typeof window?.Gleap === 'function') {
          //@ts-ignore
          window?.Gleap.identify(
            currentUser?.profile.id,
            {
              name: currentUser?.profile.full_name,
              email: currentUser?.profile.email
            },
            'GENERATED_USER_HASH'
          )
          //@ts-ignore
          window?.Gleap.registerCustomAction((customAction) => {
            if (customAction.name === 'openCrispChat') {
              if (window.$crisp) {
                window.$crisp.push(['do', 'chat:open'])
              }
            }
          })
        }
      }
    }

    return () => {
      // consolog aim to destroy
    }
  }, [jwtokenPayload?.user_type, currentUser?.profile])
  return <></>
}
export default GleapWidget
